<template>
    <div class="d-flex justify-content-center flex-wrap p-4 border rounded-4 shadow-lg bg-white">
        <div class="receipt col-12 p-4 rounded-4 bg-white" id="receipt" v-if="receipt">
            <div class="d-flex align-items-center flex-wrap">
                <div class="col-12 col-md-6 text-center">
                    <img :src="require(`@/assets/img/tenants/${omsApiKey}/${omsApiKey}.png`)" class="w-50">
                </div>
                <div class="col-12 col-md-6 d-flex justify-content-center align-items-end flex-wrap font-monospace my-4">
                    <h4 class="text-third fw-bold">
                        {{ $t('Dashboard.Payments.Modals.PaymentReceipt.labels.receipt') }} n.
                    </h4>
                    <span class="border border-second text-second fs-4 ms-2 p-2">
                        {{ receipt.payment.short_id }}
                    </span>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div class="col-12 d-flex flex-wrap my-4">
                    <div class="col-12 col-md-6 d-flex justify-content-center align-items-center flex-wrap">
                        <h3 class="text-third fw-bold">
                            {{ account.name }} {{ account.surname }}
                        </h3>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-center align-items-center flex-wrap">
                        <img :src="require(`@/assets/img/card_schemes/${receipt.bank_card.label}.svg`)" class="card-scheme">
                        <span class="text-second fs-4">
                            {{ receipt.bank_card.truncated_pan }}
                            <i class="fa-solid fa-mobile-screen-button fs-2 ms-2 text-third" v-if="receipt.bank_card.form_factor === 'DEVICE'"></i>
                        </span>
                    </div>
                </div>
                <div class="col-12 table-responsive mb-4">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center text-nowrap">
                                    <i class="fa-solid fa-book-atlas me-2"></i>
                                    {{ $t('Dashboard.Payments.Modals.PaymentReceipt.labels.fare') }}
                                </th>
                                <th class="text-center text-nowrap">
                                    <i class="fa-solid fa-clock me-2"></i>
                                    {{ $t('Dashboard.Payments.Modals.PaymentReceipt.labels.valid_from') }}
                                </th>
                                <th class="text-center text-nowrap">
                                    <i class="fa-solid fa-clock me-2"></i>
                                    {{ $t('Dashboard.Payments.Modals.PaymentReceipt.labels.valid_to') }}
                                </th>
                                <th class="text-center text-nowrap">
                                    <i class="fa-solid fa-money-bill-1-wave me-2"></i>
                                    {{ $t('Dashboard.Payments.Modals.PaymentReceipt.labels.amount') }}
                                </th>
                                <th class="text-center text-nowrap">
                                    <i class="fa-solid fa-location-dot"></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(ticket, index) in receipt.tickets" :key="index" class="font-monospace">
                                <td class="text-center align-middle">
                                    {{ ticket.fare_name }}
                                </td>
                                <td class="text-center align-middle">
                                    {{ ticket.valid_from }}
                                </td>
                                <td class="text-center align-middle">
                                    {{ ticket.valid_to }}
                                </td>
                                <td class="text-center align-middle">
                                    {{ ticket.ticket_amount }}
                                </td>
                                <td class="text-center align-middle">
                                    <span v-for="(transit_data, index) in ticket.first_validation_transit_data" :key="index">
                                        <span class="badge rounded-3 bg-third">
                                            {{ transit_data.key }}
                                        </span>
                                        <span class="badge rounded-3 bg-second ms-2">
                                            {{ transit_data.val }}
                                        </span>
                                    </span>
                                    <h6 v-if="ticket.last_validation_transit_data && ticket.last_validation_transit_data.length > 0">
                                    - - - - - -
                                    </h6>
                                    <span v-for="(transit_data, index) in ticket.last_validation_transit_data" :key="index">
                                        <span class="badge rounded-3 bg-third">
                                            {{ transit_data.key }}
                                        </span>
                                        <span class="badge rounded-3 bg-second ms-2">
                                            {{ transit_data.val }}
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center flex-wrap">
            <div class="col-12 col-sm-5 mb-2 mb-sm-0">
                <span class="btn btn-auth btn-auth-second w-100" @click="$emit('close')">
                    {{ $t('Dashboard.Payments.Modals.PaymentReceipt.close_receipt') }}
                </span>
            </div>
            <div class="col-12 col-sm-5">
                <span class="btn btn-auth btn-pulse text-uppercase w-100" @click="$emit('downloadReceipt')">
                    <i class="fa-solid fa-download me-2"></i>
                    {{ $t('Dashboard.Payments.Modals.PaymentReceipt.download') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
    name: 'PaymentReceipt',
    props: {
        receipt: Object
    },
    emits: ['downloadReceipt', 'close'],
    computed: {
        ...mapState(['account']),
        ...mapGetters(['omsApiKey'])
    }
}
</script>

<style>

</style>