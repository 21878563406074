import { createRouter, createWebHistory } from 'vue-router'
import Auth from '../views/Auth/Auth.vue'
import Login from '../views/Auth/Login.vue'
import SignUp from '../views/Auth/SignUp.vue'
import SignUpSucceeded from '../views/Auth/SignUpSucceeded.vue'
import ResendConfirmationEmail from '../views/Auth/ResendConfirmationEmail.vue'
import SentEmailIfExists from '../views/Auth/SentEmailIfExists.vue'
import SentEmailSucceeded from '../views/Auth/SentEmailSucceeded.vue'
import EmailConfirmationInProgress from '../views/Auth/EmailConfirmationInProgress.vue'
import EmailConfirmed from '../views/Auth/EmailConfirmed.vue'
import EmailUnconfirmed from '../views/Auth/EmailUnconfirmed.vue'
import ForgotPassword from '../views/Auth/ForgotPassword.vue'
import ForgotPasswordRequestInProgress from '../views/Auth/ForgotPasswordRequestInProgress.vue'
import ResetPassword from '../views/Auth/ResetPassword.vue'
import PasswordResetSucceeded from '../views/Auth/PasswordResetSucceeded.vue'
import RequestExpired from '../views/Auth/RequestExpired.vue'
import Dashboard from '../views/Dashboard/Dashboard.vue'
import DashboardIndex from '../views/Dashboard/DashboardIndex.vue'
import Tickets from '../views/Dashboard/Travels/Tickets/Tickets.vue'
import Payments from '../views/Dashboard/Travels/Payments/Payments.vue'
import BankCards from '../views/Dashboard/Travels/BankCards/BankCards.vue'
import AddBankCards from '../views/Dashboard/Travels/BankCards/AddBankCards.vue'
import Account from '../views/Dashboard/Account/Account.vue'
import EditAccountData from '../views/Dashboard/Account/EditAccountData.vue'
import AccountDataChangesSucceeded from '../views/Dashboard/Account/AccountDataChangesSucceeded.vue'
import ChangePassword from '../views/Dashboard/Account/ChangePassword.vue'
import DeletionRequest from '../views/Dashboard/Account/DeletionRequest.vue'
import DeletionConfirm from '../views/Dashboard/Account/DeletionConfirm.vue'
import AccountDeleted from '../views/Dashboard/Account/AccountDeleted.vue'

import Privacy from '../views/old_files/Privacy.vue'
import NotFound from '../views/NotFound.vue'
import store from '../store'

const routes = [
  { 
    path: '/',
    name: 'Auth',
    component: Auth,
    meta: {
      auth: false
    },
    children: [
      {
        path: '',
        name: 'Login',
        component: Login,
        meta: {
          auth: false
        }
      },
      {
        path: '/sign/up',
        name: 'SignUp',
        component: SignUp,
        meta: {
          auth: false
        }
      },
      {
        path: '/sign/up/succeeded',
        name: 'SignUpSucceeded',
        component: SignUpSucceeded,
        meta: {
          auth: false
        }
      },
      {
        path: '/resend_confirmation_email',
        name: 'ResendConfirmationEmail',
        component: ResendConfirmationEmail,
        meta: {
          auth: false
        }
      },
      {
        path: '/sent_email/if_exists',
        name: 'SentEmailIfExists',
        component: SentEmailIfExists,
        meta: {
          auth: false
        }
      },
      {
        path: '/sent_email/succeeded',
        name: 'SentEmailSucceeded',
        component: SentEmailSucceeded,
        meta: {
          auth: false
        }
      },
      {
        path: '/email/confirmation_in_progress',
        name: 'EmailConfirmationInProgress',
        component: EmailConfirmationInProgress,
        meta: {
          auth: false
        }
      },
      {
        path: '/email/confirmed',
        name: 'EmailConfirmed',
        component: EmailConfirmed,
        meta: {
          auth: false
        }
      },
      {
        path: '/email/unconfirmed',
        name: 'EmailUnconfirmed',
        component: EmailUnconfirmed,
        meta: {
          auth: false
        }
      },
      {
        path: '/forgot_password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
          auth: false
        }
      },
      {
        path: '/forgot_password/request_in_progress',
        name: 'ForgotPasswordRequestInProgress',
        component: ForgotPasswordRequestInProgress,
        meta: {
          auth: false
        }
      },
      {
        path: '/reset_password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
          auth: false
        }
      },
      {
        path: '/reset_password/succeeded',
        name: 'PasswordResetSucceeded',
        component: PasswordResetSucceeded,
        meta: {
          auth: false
        }
      },
      {
        path: '/request/expired',
        name: 'RequestExpired',
        component: RequestExpired,
        meta: {
          auth: false
        }
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'DashboardIndex',
        component: DashboardIndex,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/travels/tickets',
        name: 'Tickets',
        component: Tickets,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/travels/payments',
        name: 'Payments',
        component: Payments,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/travels/bank_cards',
        name: 'BankCards',
        component: BankCards,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/travels/bank_cards/add',
        name: 'AddBankCards',
        component: AddBankCards,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/account',
        name: 'Account',
        component: Account,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/account/edit',
        name: 'EditAccountData',
        component: EditAccountData,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/account/changes/succeeded',
        name: 'AccountDataChangesSucceeded',
        component: AccountDataChangesSucceeded,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/account/change_password',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/account/deletion_request',
        name: 'DeletionRequest',
        component: DeletionRequest,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/account/deletion_confirm',
        name: 'DeletionConfirm',
        component: DeletionConfirm,
        meta: {
          auth: true
        }
      },
      {
        path: '/dashboard/account/deleted',
        name: 'AccountDeleted',
        component: AccountDeleted,
        meta: {
          auth: true
        }
      }
    ]
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      auth: false
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  if ('auth' in to.meta && to.meta.auth && store.state.account == null) {
    return next({
      path: '/'
    })
  } else if ('auth' in to.meta && !to.meta.auth && store.state.account) {
    return next({
      path: '/dashboard'
    })
  } else {
    return next()
  }

})
export default router