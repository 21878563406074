<template>
    <component :is="api_key" :email="this.$route.query.email"></component>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
export default {
    name: 'EmailConfirmed',
    computed: {
        ...mapGetters(['omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../layouts/${this.omsApiKey}/views/Auth/EmailConfirmed.vue`))
        }
    }
}
</script>