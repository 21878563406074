<template>
    <component :is="api_key" @insertEmail="insertEmail" @insertPassword="insertPassword" :errors="errors" @showPassword="showPassword" :passwordShowed="passwordShowed" @login="login" @loginWithGoogle="loginWithGoogle" @loginWithFacebook="loginWithFacebook" @loginWithAzure="loginWithAzure"></component>
</template>

<script>
import $ from 'jquery'
import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
    name: 'LoginView',
    computed: {
        ...mapState(['facebookSdk']),
        ...mapGetters(['apiPath', 'omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../layouts/${this.omsApiKey}/views/Auth/Login.vue`))
        }
    },
    data() {
        return {
            email: null,
            password: null,
            passwordShowed: false,
            errors: {}
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer', 'setAccount']),
        insertEmail(value) {
            if (this.errors.email) {
                this.errors.email = null
            }
            this.email = value
        },
        insertPassword(value) {
            if (this.errors.password) {
                this.errors.password = null
            }
            this.password = value
        },
        showPassword() {
            this.passwordShowed = !this.passwordShowed

            if (this.passwordShowed)  {
                $('#password').attr('type', 'text')
            } else {
                $('#password').attr('type', 'password')
            }

        },
        login() {
            this.errors = {}
            this.$http
                .post(`${this.apiPath}/login`, {email: this.email, password: this.password}, {
                    headers: {
                        'x-api-key': this.omsApiKey
                    }
                })
                .then((res) => {
                    if (res.data.errors) {
                        var errors = res.data.errors
                        for (var i = 0; i < errors.length; i++) {
                            var error = errors[i]
                            if (error.path == 'email') {
                                this.errors.email = this.$t(`Auth.Login.errors.email.${error.msg}`)
                            }
                            if (error.path == 'password') {
                                this.errors.password = this.$t(`Auth.Login.errors.password.${error.msg}`)
                            }
                        }
                    } else if (res.data.account) {
                        this.setAccount(res.data.account)
                        this.$router.go('/dashboard')
                    } else {
                        switch (res.data) {
                            case 'No Account Found':
                                this.errors.email = this.$t('Auth.Login.errors.email.NoAccountFound')
                                break
                            case 'Password Incorrect':
                                this.errors.password = this.$t('Auth.Login.errors.password.invalid')
                                break
                            case 'Unconfirmed Email':
                                this.$router.replace('/email/unconfirmed')
                                break
                        }
                    }
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                })
        },
        loginWithGoogle(response) {
            this.$http
                .post(`${this.apiPath}/login_with_google`, {response}, {
                    headers: {
                        'x-api-key': this.omsApiKey
                    }
                })
                .then((res) => {
                    if (res.data.account) {
                        this.setAccount(res.data.account)
                        this.$router.go('/dashboard')
                    }
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                })
        },
        loginWithFacebook() {
            this.facebookSdk.FB
                .login((res) => {
                    if (res.status === 'connected') {
                        this.facebookSdk.FB
                            .api(`/${res.authResponse.userID}`, {fields: ['first_name', 'last_name', 'email']}, (profile) => {
                                this.$http
                                    .post(`${this.apiPath}/login_with_facebook`, {profile}, {
                                        headers: {
                                            'x-api-key': this.omsApiKey
                                        }
                                    })
                                    .then((res) => {
                                        if (res.data.account) {
                                            this.setAccount(res.data.account)
                                            this.$router.go('/dashboard')
                                        }
                                    })
                                    .catch((err) => {
                                        this.setErrorFromServer(err.message)
                                    })
                            })
                    }
                },{ scope: 'email'})
        },
        loginWithAzure(response) {
            this.$http
                .post(`${this.apiPath}/login_with_azure`, {response}, {
                    headers: {
                        'x-api-key': this.omsApiKey
                    }
                })
                .then((res) => {
                    if (res.data.account) {
                        this.setAccount(res.data.account)
                        this.$router.go('/dashboard')
                    }
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                })
        }
    }
}
</script>