<template>
    <div class="d-flex justify-content-center flex-wrap">
        <div class="col-12 col-xl-8 d-flex justify-content-between flex-wrap p-4 border rounded-4 shadow-lg">
            <div class="col-12 mb-4">
                <h1 class="text-uppercase text-third">
                    {{ $t('Dashboard.Account.personal_data') }}
                </h1>
            </div>
            <div class="col-12 mb-4">
                <h3 class="text-second">
                    {{ account.name }} {{ account.surname }}
                </h3>
            </div>
            <div class="col-12 col-md-5 mb-4 mb-md-0">
                <span class="text-uppercase">
                    {{ $t('Dashboard.Account.email') }}
                </span>
                <h5 class="text-third text-break">
                    {{ account.email }}
                </h5>
            </div>
            <div class="col-12 col-md-5">
                <span class="text-uppercase">
                    {{ $t('Dashboard.Account.language') }}
                </span>
                <h5 class="text-third text-uppercase">
                    {{ $t(`${account.languages_id}`) }}
                </h5>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
    name: 'AccountView',
    computed: {
        ...mapState(['account']),
        ...mapGetters(['omsApiKey'])
    }
}
</script>

<style lang="scss" scoped>
</style>