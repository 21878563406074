<template>
    <component :is="api_key" @insertName="insertName" @insertSurname="insertSurname" @selectLanguage="selectLanguage" @applyChanges="applyChanges" @cancel="cancel" :errors="errors"></component>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
    name: 'EditAccountData',
    computed: {
        ...mapState(['account']),
        ...mapGetters(['apiPath', 'omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../../layouts/${this.omsApiKey}/views/Dashboard/Account/EditAccountData.vue`))
        }
    },
    data() {
        return {
            accountDataEdited: {
                id: null,
                name: null,
                surname: null,
                language: this.$i18n.locale
            },
            errors: {}
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer', 'setAccount']),
        insertName(value) {
            if (this.errors.name) {
                this.errors.name = null
            }
            this.accountDataEdited.name = value
        },
        insertSurname(value) {
            if (this.errors.surname) {
                this.errors.surname = null
            }
            this.accountDataEdited.surname = value
        },
        selectLanguage(value) {
            this.errors = {}
            this.accountDataEdited.language = value
        },
        applyChanges() {
            this.$http
                .post(`${this.apiPath}/edit`, { account: this.accountDataEdited }, {
                    headers: {
                        'x-api-key': this.omsApiKey
                    }
                })
                .then((res) => {
                    if (res.data.errors) {
                        var errors = res.data.errors
                        for (var i = 0; i < errors.length; i++) {
                            var error = errors[i]
                            if (error.path == 'account.name') {
                                this.errors.name = this.$t(`Dashboard.EditAccountData.errors.name.${error.msg}`)
                            }
                            if (error.path == 'account.surname') {
                                this.errors.surname = this.$t(`Dashboard.EditAccountData.errors.surname.${error.msg}`)
                            }
                            if (error.path == 'account.language') {
                                this.errors.language = this.$t(`Dashboard.EditAccountData.errors.language.${error.msg}`)
                            }
                        }
                    } else if (res.data.account) {
                        this.setAccount(res.data.account)
                        this.$router.replace('/dashboard/account/changes/succeeded')
                    }
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                })
        },
        cancel() {
            if (this.account.languages_id != this.$i18n.locale) {
                this.$i18n.locale = this.account.languages_id
                localStorage.setItem('session_language', this.account.languages_id)
            }
            this.$router.replace('/dashboard/account')
        }
    },
    created() {
        this.accountDataEdited.id = this.account.id
        this.accountDataEdited.name = this.account.name
        this.accountDataEdited.surname = this.account.surname
        this.accountDataEdited.language = this.account.languages_id
    }
}
</script>