<template>
    <DateRanges @filterByDate="$emit('filterByDate', $event)" :date="date"/>
    <table id="datatable" class="table table-striped table-hover rounded-4 overflow-hidden my-5 w-100">
        <thead>
            <tr>
                <th class="no-sort"></th>
                <th class="text-center text-nowrap">
                    <i class="fa-solid fa-book-atlas me-2"></i>
                    {{ $t('DataTable.labels.fare') }}
                </th>
                <th class="text-center text-nowrap">
                    <i class="fa-solid fa-coins me-2"></i>
                    {{ $t('DataTable.labels.amount') }}
                </th>
                <th class="text-center text-nowrap">
                    <i class="fa-solid fa-credit-card me-2"></i>
                    {{ $t('DataTable.labels.truncated_pan') }}
                </th>
                <th class="text-center text-nowrap">
                    <i class="fa-solid fa-location-dot me-2"></i>
                    {{ $t('DataTable.labels.first_tap') }}
                </th>
                <th class="text-center text-nowrap">
                    <i class="fa-solid fa-location-dot me-2"></i>
                    {{ $t('DataTable.labels.last_tap') }}
                </th>
                <th class="text-center text-nowrap">
                    <i class="fa-solid fa-clock me-2"></i>
                    {{ $t('DataTable.labels.valid_from') }}
                </th>
                <th class="text-center text-nowrap">
                    <i class="fa-solid fa-clock me-2"></i>
                    {{ $t('DataTable.labels.valid_to') }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="tr in tbody" :key="tr.id">
                <td class="dtr-control text-center align-middle">
                    <i class="fa-solid fa-chevron-down"></i>
                </td>
                <td class="text-nowrap align-middle" @click="$emit('getDetails', tr)">
                    <div class="d-flex justify-content-center align-items-center">
                        <span class="badge rounded-pill fare">
                            {{ tr.name }}
                        </span>
                    </div> 
                </td>
                <td class="text-center text-nowrap align-middle" @click="$emit('getDetails', tr)">
                    <span class="badge rounded-pill amount">
                        {{ this.$formatAmount(tr.amount, tr.currency) }}
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle" @click="$emit('getDetails', tr)">
                    <div class="d-flex justify-content-center align-items-center">
                        <img :src="require(`@/assets/img/card_schemes/${this.$formatLabel(tr.label)}.svg`)" class="card-scheme">
                        <span class="truncated_pan">
                            {{ tr.truncated_pan }}
                            <i class="fa-solid fa-mobile-screen-button fs-2 ms-2" v-if="tr.form_factor === 'DEVICE'"></i>
                        </span>
                    </div>
                </td>
                <td class="text-nowrap align-middle" @click="$emit('getDetails', tr)">
                    <span v-for="(tap, index) in tr.first_tap" :key="index" class="tap">
                        <span class="badge rounded-pill key me-2">
                            {{ tap.key }}
                        </span>
                        <span class="badge rounded-pill val">
                            {{ tap.val }}
                        </span>
                        <br>
                    </span>
                </td>
                <td class="text-nowrap align-middle" @click="$emit('getDetails', tr)">
                    <span v-for="(tap, index) in tr.last_tap" :key="index" class="tap">
                        <span class="badge rounded-pill key me-2">
                            {{ tap.key }}
                        </span>
                        <span class="badge rounded-pill val">
                            {{ tap.val }}
                        </span>
                        <br>
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle" @click="$emit('getDetails', tr)">
                    <span class="badge rounded-pill valid_from font-monospace">
                        {{ this.$formatDate(tr.valid_from, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone) }}
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle" @click="$emit('getDetails', tr)">
                    <span class="badge rounded-pill valid_to font-monospace">
                        {{ this.$formatDate(tr.valid_to, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone) }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import $ from 'jquery'
import { mapState } from 'vuex'
import DateRanges from '@/components/DateRanges.vue'
export default {
    name: 'TicketsDataTable',
    props: {
        date: String,
        tbody: Array
    },
    emits: ['filterByDate', 'getDetails'],
    components: {
        DateRanges
    },
    computed: {
        ...mapState(['account'])
    },
    created() {
        $(document).ready( () => {
            var TicketsDataTable = $('#datatable').DataTable({
                dom: `<'d-flex justify-content-center justify-content-md-between align-items-center flex-wrap'
						<'col-12 col-md-4'l>
                        <'col-12 col-md-4'<"dater">>
						<'col-12 col-md-4'f>>` +
                    "<'d-flex'<'col-12'tr>>" +
                    `<'d-flex align-items-center flex-wrap'
                        <'col-12 col-md-5 text-center text-md-start'i>
                        <'col-12 col-md-7'p>>`,
                language: {
                    lengthMenu: this.$t('DataTable.lengthMenu'),
                    search: '',
                    searchPlaceholder: this.$t('DataTable.search'),
                    info: this.$t('DataTable.info'),
                    paginate: {
                        previous: '<i class="fa-solid fa-circle-chevron-left"></i>',
                        next: '<i class="fa-solid fa-circle-chevron-right"></i>'
                    },
                    zeroRecords: this.$t('DataTable.zeroRecords'),
                    infoFiltered: this.$t('DataTable.infoFiltered'),
                    infoEmpty: this.$t('DataTable.infoEmpty'),
                },
                responsive: {
                    details: {
                        type: 'column',
                        renderer: (api, rowIdx, columns) => {
                            var data = $.map(columns, (col) => {
                                if (col.hidden === false) {
                                    return ''
                                }
                                var row = $('<tr>', {
                                    'data-dt-row': col.rowIndex,
                                    'data-dt-column': col.columnIndex
                                })
                                    .append(
                                        $('<td>', {
                                            class: "py-2"
                                        })
                                            .html(col.title)
                                            .css('font-size', '14px'))
                                    .append(
                                        $('<td>', {
                                            class: col.className
                                        }).html(col.data))

                                return row.get(0).outerHTML
                            }).join('')
                            return data ? $('<table class="w-100 table-sm">').append(data) : false
                        }
                    }
                },
                columnDefs: [{
                    className: 'dtr-control',
                    orderable: false,
                    width: '0%',
                    targets: 0
                }, {
                    width: '16%',
                    orderable: false,
                    targets: '_all'
                }, {
                    type: 'date',
                    targets: [5,6]
                }],
                order: [
                   [5, 'desc'] 
                ]
            })
            $('.dater').append($(".dataTables_date"))
            $('input').removeClass('form-control form-control-sm')
            $('select').removeClass('form-select form-select-sm')

            TicketsDataTable.on('responsive-resize', (e, datatable) => {
                datatable.responsive.rebuild().columns.adjust().responsive.recalc()
            })

            TicketsDataTable.on('responsive-display', (e, api, row, showHide) => {
                var rowOpened = $(api.row(row.index()).node())
                if (showHide) {
                    rowOpened.find('td.dtr-control').empty().append(`<i class="fa-solid fa-chevron-up"></i>`)
                } else {
                    rowOpened.find('td.dtr-control').empty().append(`<i class="fa-solid fa-chevron-down"></i>`)
                }
            })
        })
    }
}
</script>

<style>

</style>