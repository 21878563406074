var main = require('@/main')
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/style/ui/f7fb61e7-ee2f-47c1-ad44-02c1c161c94f.scss'

const app = createApp(App)
app.config.globalProperties.$http = main.Axios
app.config.globalProperties.$formatDate = (date, locales_id, time_zone) => {
    return main.timezone.tz(main.timezone.utc(date), time_zone).locale(locales_id).format('L LTS')
}
app.config.globalProperties.$formatAmount = (amount, currency = 'EUR') => {
    amount = amount/100
    amount.toFixed(2)
    var formatter = new Intl.NumberFormat('en-EN', {
        style: 'currency',
        currency
    })
    return formatter.format(amount)
}
app.config.globalProperties.$formatLabel = (label) => {
    switch (label) {
        case 'AMERICAN EXPRESS':
            label = 'AMEX'
        break;
        case 'MAESTRO':
            label = 'Maestro'
        break;
        case 'MASTERCARD':
            label = 'Mastercard'
        break;
        case 'VPAY':
            label = 'V_Pay'
        break;
        case 'VISA Electron':
            label = 'Visa_Electron'
        break;
        case 'VISA ELECTRON':
            label = 'Visa_Electron'
        break;
        case 'VISA':
            label = 'VISA'
        break;
        case 'VISA Debit/Credit':
            label = 'VISA'
        break;
        default:
            label = 'scheme'
            break;
    }
    return label
}
app.use(router)
app.use(main.store)
app.use(main.i18n)
app.use(main.ToastPlugin, {
    type: 'success',
    duration: 1000 * 5,
    dismissible: true,
    position: 'top-right'
})
app.use(main.VCalendar)
app.use(main.VueReCaptcha, { siteKey: main.config.recaptcha.site_key })
app.mount('#app')