<template>
    <header class="d-flex justify-content-between align-items-center flex-wrap bg-second p-2">
        <div class="col-6">
            <a href="https://www.malpensaexpress.it/">
                <img :src="require(`@/assets/img/tenants/${omsApiKey}.svg`)">
            </a>
        </div>
        <div class="col-2 text-end">
            <LanguagesSelect class="select-auth"/>
        </div>
    </header>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import LanguagesSelect from '@/components/LanguagesSelect.vue'
export default {
    name: 'HeaderComp',
    components: {
        LanguagesSelect
    },
    computed: {
        ...mapGetters(['omsApiKey'])
    },
    created() {
        $(window).scroll(function() {    
            var scroll = $(window).scrollTop();

            if (scroll > 0) {
                $('header').addClass('fixed');
            } else {
                $('header').removeClass('fixed');
            }
        })
    }
}
</script>

<style lang="scss" scoped>
    header {
        &.fixed {
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
        }

        img {
            width: 35%;
        }

        @media screen and (max-width: 576px) {
            img {
                width: 50%;
            }
        }
    }
</style>