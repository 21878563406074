<template>
    <component :is="api_key" @insertNewPassword="insertNewPassword" @insertConfirmNewPassword="insertConfirmNewPassword" @showNewPassword="showNewPassword" :newPasswordShowed="newPasswordShowed" @showConfirmNewPassword="showConfirmNewPassword" :confirmNewPasswordShowed="confirmNewPasswordShowed" @resetPassword="resetPassword" :errors="errors"></component>
</template>

<script>
import $ from 'jquery'
import { defineAsyncComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'ResetPassword',
    computed: {
        ...mapGetters(['apiPath', 'omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../layouts/${this.omsApiKey}/views/Auth/ResetPassword.vue`))
        }
    },
    data() {
        return {
            new_password: null,
            confirm_new_password: null,
            newPasswordShowed: false,
            confirmNewPasswordShowed: false,
            errors: {}
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer']),
        insertNewPassword(value) {
            if (this.errors.new_password) {
                this.errors.new_password = null
            }
            this.new_password = value
        },
        insertConfirmNewPassword(value) {
            if (this.errors.confirm_new_password) {
                this.errors.confirm_new_password = null
            }
            this.confirm_new_password = value
        },
        showNewPassword() {
            this.newPasswordShowed = !this.newPasswordShowed

            if (this.newPasswordShowed)  {
                $('#new_password').attr('type', 'text')
            } else {
                $('#new_password').attr('type', 'password')
            }

        },
        showConfirmNewPassword() {
            this.confirmNewPasswordShowed = !this.confirmNewPasswordShowed

            if (this.confirmNewPasswordShowed)  {
                $('#confirm_new_password').attr('type', 'text')
            } else {
                $('#confirm_new_password').attr('type', 'password')
            }

        },
        resetPassword() {
            this.$http
                .post(`${this.apiPath}/reset_password`, {token: this.$route.query.token, new_password: this.new_password, confirm_new_password: this.confirm_new_password}, {
                    headers: {
                        'x-api-key': this.omsApiKey
                    }
                }).then((res) => {
                    if (res.data.errors) {
                        var errors = res.data.errors
                        for (var i = 0; i < errors.length; i++) {
                            var error = errors[i]
                            if (error.path == 'new_password') {
                                this.errors.new_password = this.$t(`Auth.ResetPassword.errors.new_password.${error.msg}`)
                            }
                            if (error.path == 'confirm_new_password') {
                                this.errors.confirm_new_password = this.$t(`Auth.ResetPassword.errors.confirm_new_password.${error.msg}`)
                            }
                        }
                    } else {
                        switch (res.data) {
                            case 'Request Expired':
                                this.$router.replace('/request/expired')
                                break
                            default:
                                this.$router.replace('/reset_password/succeeded')
                                break
                        }
                    }

                }).catch((err) => {
                    this.setErrorFromServer(err.message)
                })
        }
    },
}
</script>