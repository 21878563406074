<template>
    <div class="d-flex justify-content-center flex-wrap">
        <div class="col-12 col-xl-8 border rounded-4 shadow-lg">
            <SIATokenizer/>
        </div>
    </div>
    <transition name="fade-modal">
        <modal v-if="tokenizer_response" @close="tokenizer_response = null">
            <template v-slot:header>
                <div class="d-flex flex-wrap tokenizer_response">
                    <h1>
                        {{ $t(`Dashboard.AddBankCards.Modals.tokenizer_response.${tokenizer_response.res}`) }}
                    </h1>
                    <h3 class="mt-2" v-if="tokenizer_response.truncated_pan">
                        {{ tokenizer_response.truncated_pan }}
                    </h3>
                </div>
            </template>
        </modal>
    </transition>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import SIATokenizer from '@/components/SIATokenizer.vue'
import Modal from '@/components/Modal.vue'
export default {
    name: 'AddBankCards',
    components : {
        SIATokenizer,
        Modal
    },
    computed: {
        ...mapState(['account']),
        ...mapGetters(['apiPath', 'omsApiPath', 'omsApiKey'])
    },
    data() {
        return {
            tokenizer_response: null
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer']),
        decoded_res(SIA_res) {
            var short_id = null
            var pan_short_id = null
            var payment_account_reference = null
            
            if (SIA_res) {
                pan_short_id = SIA_res
            } 

            this.$http
                .get(`${this.omsApiPath}/bank_cards/get/${this.omsApiKey}///${short_id}/${pan_short_id}/${payment_account_reference}`)
                .then((response) => {
                    if (response.data.payload.length > 0) {
                        this.$http
                            .post(`${this.apiPath}/bank_cards/add`, { bank_card: response.data.payload[0] , accounts_id: this.account.id }, {
                                headers: {
                                    'x-api-key': this.omsApiKey
                                }
                            })
                            .then((res) => {
                                switch (res.data) {
                                    case 'Bank Card Already Added':
                                        this.tokenizer_response = {
                                            res: 'already_added',
                                            truncated_pan: response.data.payload[0].truncated_pan
                                        }
                                        break
                                    default:
                                        this.tokenizer_response = {
                                            res: 'succeeded',
                                            truncated_pan: response.data.payload[0].truncated_pan
                                        }
                                    break
                                }
                            })
                            .catch((err) => {
                                this.setErrorFromServer(err.message)
                            })
                    } else {
                        this.tokenizer_response = {
                            res: 'unused'
                        }
                    }
                    setTimeout(() => {
                        this.$router.replace('/dashboard/travels/bank_cards')
                    }, 2500)
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                })
        }
    },
    created() {
        if (Object.keys(this.$route.query).length !== 0) {
            this.decoded_res(this.$route.query.PANALIAS)
        }
    }
}
</script>