<template>
    <footer class="text-center p-2">
        <small class="text-third">
            © 2020 Trenord P.IVA 06705490966
        </small>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'FooterComp',
    computed: {
        ...mapGetters(['omsApiKey'])
    }
}
</script>

<style lang="scss" scoped>
</style>