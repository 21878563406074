<template>
    <div class="text-center version p-4" :class="config.env">
        <h6 class="fw-bold">
            MyTravels Portal v{{ version.version }}
        </h6>
        <small class="fw-lighter fst-italic">
            Powered by
        </small>
        <div class="text-center">
            <img src="@/assets/img/ConduentLogo.svg">
        </div>
        <small>
            Copyright &copy; {{ year }} Conduent Business Solutions. {{ $t('Version.copyright') }}
        </small>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
export default {
    name: 'VersionComp',
    computed: {
        ...mapState(['config', 'version'])
    },
    data() {
        return {
            year: moment().year()
        }
    },
}
</script>

<style lang="scss" scoped>

    h6,
    small {
        text-shadow: 0 0 8px white;
    }

    img {
        width: 5%;
    }

    @media screen and (max-width: 992px) {
        img {
            width: 15%;
        }
    }
    @media screen and (max-width: 576px) {
        img {
            width: 30%;
        }
    }
</style>