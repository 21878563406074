<template>
    <component :is="api_key" @insertEmail="insertEmail" @forgotPassword="forgotPassword" :errors="errors"></component>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'ForgotPassword',
    computed: {
        ...mapGetters(['apiPath', 'omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../layouts/${this.omsApiKey}/views/Auth/ForgotPassword.vue`))
        }
    },
    data() {
        return {
            email: null,
            errors: {}
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer']),
        insertEmail(value) {
            if (this.errors.email) {
                this.errors.email = null
            }
            this.email = value
        },
        forgotPassword(disableErrorOnAccountNotFound) {
            this.$http
                .post(`${this.apiPath}/email/send_forgot_password_request`, { email: this.email }, {
                    headers: {
                        'x-api-key': this.omsApiKey
                    }
                }).then((res) => {
                    if (res.data.errors) {
                        var errors = res.data.errors
                        for (var i = 0; i < errors.length; i++) {
                            var error = errors[i]
                            if (error.path == 'email') {
                                this.errors.email = this.$t(`Auth.ForgotPassword.errors.email.${error.msg}`)
                            }
                        }
                    } else if (res.data.data) {
                        this.$router.replace({
                            path: '/sent_email/succeeded',
                            query: {
                                sent_to: res.data.data.to
                            }
                        })
                    } else {
                        switch (res.data) {
                            case 'No Account Found':
                                if (disableErrorOnAccountNotFound) {
                                    this.$router.replace({
                                        path: '/sent_email/if_exists',
                                        query: {
                                            sent_to: this.email
                                        }
                                    })
                                } else {
                                    this.errors.email = this.$t('Auth.ForgotPassword.errors.email.NoAccountFound')
                                }
                                break
                            case 'Unconfirmed Email':
                                this.errors.email = this.$t('Auth.ForgotPassword.errors.email.UnconfirmedEmail')
                                break
                        }
                    }
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                })
        }
    }
}
</script>