<template>
    <component :is="api_key"></component>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'EmailConfirmationInProgress',
    computed: {
        ...mapGetters(['apiPath', 'omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../layouts/${this.omsApiKey}/views/Auth/EmailConfirmationInProgress.vue`))
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer']),
        confirm_email() {
            this.$http
                .post(`${this.apiPath}/email/confirm_email`, { token: this.$route.query.token }, {
                    headers: {
                        "x-api-key": this.omsApiKey
                    },
                })
                .then((res) => {
                    switch (res.data) {
                        case 'Request Expired':
                            this.$router.replace('/request/expired')
                            break;
                    
                        default:
                            this.$router.replace({
                                path: '/email/confirmed',
                                query: { email: res.data }
                            })
                            break;
                    }
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                })
        }
    },
    created() {
        this.confirm_email()
    }
}
</script>