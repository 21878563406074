<template>
    <div class="d-flex flex-wrap">
        <div class="col-12 text-center text-lg-end mb-4 px-4">
            <router-link to="/dashboard/travels/bank_cards/add" class="btn btn-auth btn-auth-second text-decoration-none">
                {{ $t('Dashboard.BankCards.addBankCard') }}
            </router-link>
        </div>
        <div class="col-12">
            <DataTable :tbody="BankCards" @getDetails="details = $event" @askDelete="onAskDelete = $event" v-if="!loader && BankCards"/>
        </div>
    </div>
    <transition name="fade-modal">
        <modal v-if="details" @close="details = null">
            <template v-slot:body>
                <div class="d-flex justify-content-between align-items-center flex-wrap p-4 border rounded-4 shadow-lg bg-white">
                    <div class="col-12">
                        <h1 class="text-uppercase text-third">
                            {{ $t('Dashboard.BankCards.Modals.BankCardDetails.modalName') }}
                        </h1>
                    </div>
                    <div class="col-12 d-flex align-items-center flex-wrap my-4">
                        <img :src="require(`@/assets/img/card_schemes/${this.$formatLabel(details.label)}.svg`)" class="card-scheme">
                        <h3 class="text-second">
                            {{ details.truncated_pan }}
                        </h3>
                        <i class="fa-solid fa-mobile-screen-button fs-2 ms-2 text-third" v-if="details.form_factors_name === 'DEVICE'"></i>
                    </div>
                    <div class="col-12 col-sm-5 mb-4">
                        <span class="text-uppercase">
                            {{ $t('Dashboard.BankCards.Modals.BankCardDetails.alias_pan') }}
                        </span>
                        <h5 class="text-third text-break">
                            {{ details.alias_pan }}
                        </h5>
                    </div>
                    <div class="col-12 col-sm-5 mb-4">
                        <span class="text-uppercase">
                            {{ $t('Dashboard.BankCards.Modals.BankCardDetails.aid') }}
                        </span>
                        <h5 class="text-third text-break">
                            {{ details.aid }}
                        </h5>
                    </div>
                    <div class="col-12 col-sm-5 mb-4">
                        <span class="text-uppercase">
                            {{ $t('Dashboard.BankCards.Modals.BankCardDetails.created') }}
                        </span>
                        <h5 class="text-third text-break">
                            {{ this.$formatDate(details.created, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone) }}
                        </h5>
                    </div>
                    <div class="col-12 col-sm-5 mb-4">
                        <span class="text-uppercase">
                            {{ $t('Dashboard.BankCards.Modals.BankCardDetails.updated') }}
                        </span>
                        <h5 class="text-third text-break">
                            {{ this.$formatDate(details.updated, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone) }}
                        </h5>
                    </div>
                    <div class="col-12 d-flex justify-content-center flex-wrap">
                        <div class="col-12 col-sm-5">
                            <span class="btn btn-auth btn-auth-second w-100" @click="details = null">
                                {{ $t('Dashboard.BankCards.Modals.BankCardDetails.close_details') }}
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="onAskDelete">
            <template v-slot:body>
                <div class="d-flex justify-content-between align-items-center flex-wrap bg-white p-4 border rounded-4 shadow-lg">
                    <div class="col-12 d-flex align-items-center flex-wrap mb-4">
                        <img :src="require(`@/assets/img/card_schemes/${this.$formatLabel(onAskDelete.label)}.svg`)" class="card-scheme">
                        <h3 class="text-second">
                            {{ onAskDelete.truncated_pan }}
                        </h3>
                        <i class="fa-solid fa-mobile-screen-button fs-2 ms-2 text-third" v-if="onAskDelete.form_factors_name === 'DEVICE'"></i>
                    </div>
                    <div class="col-12 mb-4">
                        <p class="text-second fw-bold">
                            {{ $t('Dashboard.BankCards.Modals.onAskDelete.info') }}
                        </p>
                        <p>
                            {{ $t('Dashboard.BankCards.Modals.onAskDelete.ask') }}
                        </p>
                    </div>
                    <div class="col-12 col-sm-5">
                        <span class="btn btn-bc-delete option w-100" @click="onAskDelete = null">
                            {{ $t('Dashboard.BankCards.Modals.onAskDelete.cancel') }}
                        </span>
                    </div>
                    <div class="col-12 col-sm-5">
                        <span class="btn btn-auth btn-auth-second w-100" @click="removeBankCard">
                            {{ $t('Dashboard.BankCards.Modals.onAskDelete.confirm') }}
                        </span>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import Modal from "@/components/Modal.vue"
import DataTable from "../../../../components/Dashboard/Travels/DataTables/BankCardsDataTable.vue"
export default {
    name: 'BankCards',
    components: {
        DataTable,
        Modal
    },
    computed: {
        ...mapState(['loader', 'account']),
        ...mapGetters(['apiPath', 'omsApiKey']),
    },
    data() {
        return {
            BankCards: null,
            details: null,
            onAskDelete: null
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer', 'setLoader']),
        removeBankCard() {
            this.setLoader(true)
            this.$http
                .post(`${this.apiPath}/bank_cards/remove`, { bank_cards_id: this.onAskDelete.id}, {
                    headers: {
                        'x-api-key': this.omsApiKey
                    }
                })
                .then(() => {
                    this.onAskDelete = null
                    this.setLoader(false)
                    this.$router.go('/dashboard')
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                    this.setLoader(false)
                })
        },
        async getBankCards() {
            this.setLoader(true)
            var BankCards = []

            try {
                const res = await this.$http.post(`${this.apiPath}/bank_cards/get`,
                {
                    accounts_id: this.account.id
                }, {
                    headers: {
                        'x-api-key': this.omsApiKey
                    }
                })
                var bank_cards = res.data
                for (var i = 0; i < bank_cards.length; i++) {
                    var bank_card = bank_cards[i]
                    BankCards.push(bank_card)
                }
                this.setLoader(false)
                return BankCards
            } catch (err) {
                this.setErrorFromServer(err.message)
                this.setLoader(false)
            }
        }
    },
    async mounted() {
        this.BankCards = await this.getBankCards()   
    }
}
</script>