<template>
    <nav class="d-flex justify-content-between align-items-center flex-wrap bg-second p-4 p-sm-2" :class="config.env">
        <div class="col-12 col-sm-2 col-xxl-1 mb-4 mb-sm-0">
            <a href="https://www.malpensaexpress.it/">
                <img :src="require(`@/assets/img/tenants/${omsApiKey}.svg`)" class="w-100">
            </a>
        </div>
        <div class="col-12 col-sm-8 d-flex justify-content-center justify-content-sm-end align-items-center">
            <i class="fa-regular fa-circle-user text-third fs-4" data-bs-toggle="dropdown"></i>
            <span data-bs-toggle="dropdown" class="ms-2 me-4 text-white text-nowrap" role="button">
                {{ account.name }} {{ account.surname }}
            </span>
            <ul class="dropdown-menu dropdown-menu-end dropdowns">
                <li>
                    <router-link class="dropdown-item" to="/dashboard">
                        <i class="fa-solid fa-gauge-high me-2"></i>
                        {{ $t('Navbar.dropdown.Dashboard') }}
                    </router-link>
                </li>
                <li>
                    <router-link class="dropdown-item" to="/dashboard/account">
                        <i class="fa-regular fa-circle-user me-2"></i>
                        {{ $t('Navbar.dropdown.Account') }}
                    </router-link>
                </li>
                <li>
                    <span class="dropdown-item" @click="this.$store.dispatch('logout')">
                        <i class="fa-solid fa-sign-out-alt me-2"></i>
                        {{ $t('Navbar.dropdown.logout') }}
                    </span>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
    name: 'NavbarComp',
    computed: {
        ...mapState(['config', 'account']),
        ...mapGetters(['omsApiKey'])
    }
}
</script>