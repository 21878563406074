<template>
    <div class="d-flex align-items-center flex-wrap">
        <div class="col-12 text-center">
            <h1 class="text-warning font-monospace">
                404
            </h1>
            <h2 class="font-monospace">
                {{ $t('NotFound.lost') }}
            </h2>
        </div>
        <div class="col-12 text-center my-5">
            <img src="@/assets/img/lost.png" class="w-25" alt="lost">
        </div>
        <div class="col-12 text-center">
            <router-link to="/" class="btn btn-lg btn-outline-dark rounded-pill font-monospace">
                {{ $t('NotFound.back') }}
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>

<style lang="scss" scoped>
    h1 {
        font-size: 100px;
    }
</style>