<template>
    <Navbar/>
    <main class="d-flex justify-content-center flex-wrap py-4 px-2">
        <div class="col-12 col-xl-8 d-flex flex-wrap p-4 border rounded-5 shadow-lg bg-white">
            <div class="col-12">
                <h2 class="text-first">
                    <router-link to="/dashboard" class="text-third router-link" v-if="this.$route.name !== 'DashboardIndex'">
                        <i class="fa-solid fa-chevron-left"></i>
                        {{ $t('Dashboard.back') }}
                    </router-link>
                </h2>
            </div>
            <div class="col-12 text-center my-4">
                <h1 class="text-dark">
                    {{ this.$route.name !== 'DashboardIndex' ? $t(`Dashboard.${this.$route.name}.RouteName`) : $t(`Dashboard.${this.$route.name}.Description.trenord.title`) }}
                </h1>
                <h2 class="text-dark" v-if="this.$route.name === 'DashboardIndex'">
                    {{ $t(`Dashboard.${this.$route.name}.Description.trenord.subtitle`) }}
                </h2>
            </div>
            <div class="col-12">
                <router-view></router-view>
            </div>
        </div>
    </main>
</template>

<script>
import Navbar from '../../components/Dashboard/Navbar.vue'
export default {
    name: 'DashboardView',
    components: {
        Navbar
    }
}
</script>