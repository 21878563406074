<template>
    <div class="custom-modal-backdrop d-flex justify-content-center align-items-start" @click.self="$emit('close')">
        <div class="custom-modal col-12 col-md-6 col-xxl-5">
            <slot name="header">
            </slot>

            <slot name="body">
            </slot>
            
            <slot name="footer">   
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name : 'ModalComp'
}
</script>

<style lang="scss" scoped>
    .custom-modal-backdrop {
        position: fixed;
        padding: 20px;
        inset: 0;
        background-color: rgba(black, 0.7);
        z-index: 9999;  
        overflow-y: auto;  
        
        .custom-modal {
            position: relative;
            overflow-y: auto;
            animation-name: modal;
            animation-duration: .3s;

            @keyframes modal {
                0%   {bottom:50%; top:50%;}
                100% {bottom:0px; top:0px;}
            }
        }
    }
</style>