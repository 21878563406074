<template>
    <component :is="api_key" @logout="this.$store.dispatch('logout')" v-if="BankCards"></component>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'
export default {
    name: 'DashboardIndex',
    computed: {
        ...mapState(['BankCards']),
        ...mapGetters(['omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../layouts/${this.omsApiKey}/views/Dashboard/DashboardIndex.vue`))
        }
    }
}
</script>