<template>
    <component :is="api_key" @sendDeletionRequest="sendDeletionRequest"></component>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
    name: 'DeletionRequest',
    computed: {
        ...mapState(['account']),
        ...mapGetters(['apiPath', 'omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../../layouts/${this.omsApiKey}/views/Dashboard/Account/DeletionRequest.vue`))
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer']),
        sendDeletionRequest() {
            this.$http
                .post(`${this.apiPath}/deletion_request`, { id: this.account.id }, {
                    headers: {
                        "x-api-key": this.omsApiKey,
                    }
                })
                .then(() => {
                    this.$router.replace('/dashboard/account/deletion_confirm')
                })
                .catch((err) => {
                    this.setErrorFromServer(err)
                })
        }
    }
}
</script>