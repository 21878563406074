<template>
    <span data-bs-toggle="dropdown" class="text-uppercase" role="button">
        {{ $t(`${this.$i18n.locale}`) }}
        <i class="fa-solid fa-chevron-down ms-2"></i>
        <ul class="dropdown-menu dropdowns">
            <li v-for="language in supportedLanguages" :key="language.id" @click="setLanguages(language.id)">
                <span class="dropdown-item text-center" :class="{'active' : language.id == this.$i18n.locale }">
                    {{ language.name }}
                </span>
            </li>
        </ul>
    </span>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'LanguagesSelect',
    computed: {
        ...mapState(['supportedLanguages']),
    },
    methods: {
        setLanguages(language) {
            this.$i18n.locale = language
            localStorage.setItem('session_language', language)
            this.$emit('setLanguage', language)
        }
    }
}
</script>

<style lang="scss" scoped>
    .show {
        i::before {
            content: "\f077";
        }
    }
</style>