<template>
    <component :is="api_key" @insertName="insertName" @insertSurname="insertSurname" @insertEmail="insertEmail" @insertConfirmEmail="insertConfirmEmail" @insertPassword="insertPassword" @insertConfirmPassword="insertConfirmPassword" @selectLanguage="selectLanguage" @acceptPrivacy="acceptPrivacy" @showPassword="showPassword" :passwordShowed="passwordShowed" @showConfirmPassword="showConfirmPassword" :confirmPasswordShowed="confirmPasswordShowed" @signUp="signUp" :errors="errors"></component>
</template>

<script>
import $ from 'jquery'
import { defineAsyncComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'SignUp',
    computed: {
        ...mapGetters(['apiPath', 'omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../layouts/${this.omsApiKey}/views/Auth/SignUp.vue`))
        }
    },
    data() {
        return {
            newUser: {
                name: null,
                surname: null,
                date_of_birth: null,
                email: null,
                confirm_email: null,
                password: null,
                confirm_password: null,
                language: this.$i18n.locale,
            },
            passwordShowed: false,
            confirmPasswordShowed: false,
            privacy: null,
            errors: {}
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer']),
        insertName(value) {
            if (this.errors.name) {
                this.errors.name = null
            }
            this.newUser.name = value
        },
        insertSurname(value) {
            if (this.errors.surname) {
                this.errors.surname = null
            }
            this.newUser.surname = value
        },
        insertEmail(value) {
            if (this.errors.email) {
                this.errors.email = null
            }
            this.newUser.email = value
        },
        insertConfirmEmail(value) {
            if (this.errors.confirm_email) {
                this.errors.confirm_email = null
            }
            this.newUser.confirm_email = value
        },
        insertPassword(value) {
            if (this.errors.password) {
                this.errors.password = null
            }
            this.newUser.password = value
        },
        insertConfirmPassword(value) {
            if (this.errors.confirm_password) {
                this.errors.confirm_password = null
            }
            this.newUser.confirm_password = value
        },
        showPassword() {
            this.passwordShowed = !this.passwordShowed

            if (this.passwordShowed)  {
                $('#password').attr('type', 'text')
            } else {
                $('#password').attr('type', 'password')
            }

        },
        showConfirmPassword() {
            this.confirmPasswordShowed = !this.confirmPasswordShowed

            if (this.confirmPasswordShowed)  {
                $('#confirm_password').attr('type', 'text')
            } else {
                $('#confirm_password').attr('type', 'password')
            }

        },
        selectLanguage(value) {
            this.errors = {}
            this.newUser.language = value
        },
        acceptPrivacy(value) {
            if (this.errors.privacy) {
                this.errors.privacy = null
            }
            this.privacy = value
        },
        signUp() {
            this.errors = {}
            if (!this.privacy) {
                this.errors.privacy = this.$t('Auth.SignUp.errors.privacy.required')
            } else {
                this.$http
                    .post(`${this.apiPath}/sign_up`, { user: this.newUser }, {
                        headers: {
                            'x-api-key': this.omsApiKey
                        }
                    })
                    .then((res) => {
                        if (res.data.errors) {
                            var errors = res.data.errors
                            for (var i = 0; i < errors.length; i++) {
                                var error = errors[i]
                                if (error.path == 'user.name') {
                                    this.errors.name = this.$t(`Auth.SignUp.errors.name.${error.msg}`)
                                }
                                if (error.path == 'user.surname') {
                                    this.errors.surname = this.$t(`Auth.SignUp.errors.surname.${error.msg}`)
                                }
                                if (error.path == 'user.email') {
                                    this.errors.email = this.$t(`Auth.SignUp.errors.email.${error.msg}`)
                                }
                                if (error.path == 'user.confirm_email') {
                                    this.errors.confirm_email = this.$t(`Auth.SignUp.errors.confirm_email.${error.msg}`)
                                }
                                if (error.path == 'user.password') {
                                    this.errors.password = this.$t(`Auth.SignUp.errors.password.${error.msg}`)
                                }
                                if (error.path == 'user.confirm_password') {
                                    this.errors.confirm_password = this.$t(`Auth.SignUp.errors.confirm_password.${error.msg}`)
                                }
                                if (error.path == 'user.language') {
                                    this.errors.language = this.$t(`Auth.SignUp.errors.language.${error.msg}`)
                                }
                            }
                        } else {
                            switch (res.data) {
                                case 'Account Already Exists':
                                    this.errors.email = this.$t('Auth.SignUp.errors.email.AccountAlreadyExists')
                                    break
                                case 'Account Registered Successfully':
                                    this.$http
                                        .post(`${this.apiPath}/email/send_email_confirmation_request`, { email: this.newUser.email }, {
                                            headers: {
                                                'x-api-key': this.omsApiKey
                                            }
                                        }).then((res) => {
                                            this.$router.replace({
                                                path: '/sign/up/succeeded',
                                                query: {
                                                    sent_to: res.data.data.to
                                                }
                                            })
                                        })
                                        .catch((err) => {
                                            this.setErrorFromServer(err.message)
                                        })
                                    break
                            }
                        }
                    })
                    .catch((err) => {
                        this.setErrorFromServer(err.message)
                    })
            }

        }
    },
}
</script>