<template>
    <div class="d-flex flex-wrap">
        <div class="col-12">
            <DataTable :date="selectedDate" :tbody="tickets" @filterByDate="filterByDate" @getDetails="details = $event" v-if="!loader && tickets"/>
        </div>
    </div>
    <transition name="fade-modal">
        <modal v-if="details" @close="details = null">
            <template v-slot:body>
                <div class="d-flex justify-content-between flex-wrap p-4 border rounded-4 shadow-lg bg-white">
                    <div class="col-12 mb-4">
                        <h1 class="text-uppercase text-third">
                            {{ details.name }}
                        </h1>
                    </div>
                    <div class="col-12 col-sm-5 d-flex align-items-center flex-wrap mb-4">
                        <img :src="require(`@/assets/img/card_schemes/${this.$formatLabel(details.label)}.svg`)" class="card-scheme">
                        <h3 class="text-second">
                            {{ details.truncated_pan }}
                        </h3>
                        <i class="fa-solid fa-mobile-screen-button fs-2 ms-2 text-third" v-if="details.form_factor === 'DEVICE'"></i>
                    </div>
                    <div class="col-12 col-sm-5 mb-4">
                        <span class="text-uppercase">
                            {{ $t('Dashboard.Tickets.Modals.TicketDetails.amount') }}
                        </span>
                        <h5 class="text-third text-break">
                            {{ this.$formatAmount(details.amount, details.currency) }}
                        </h5>
                    </div>
                    <div class="col-12 mb-4">
                        <span class="text-uppercase">
                            {{ $t('Dashboard.Tickets.Modals.TicketDetails.id') }}
                        </span>
                        <h5 class="text-third text-break">
                            {{ details.id }}
                        </h5>
                    </div>
                    <div class="col-12 col-sm-5 mb-4">
                        <span class="text-uppercase">
                            {{ $t('Dashboard.Tickets.Modals.TicketDetails.first_tap') }}
                        </span>
                        <h5 v-for="(tap, index) in details.first_tap" :key="index" class="mb-2">
                            <span class="badge rounded-3 bg-third me-2">
                                {{ tap.key }}
                            </span>
                            <span class="badge rounded-3 bg-second">
                                {{ tap.val }}
                            </span>
                        </h5>
                    </div>
                    <div class="col-12 col-sm-5 mb-4">
                        <span class="text-uppercase">
                            {{ $t('Dashboard.Tickets.Modals.TicketDetails.last_tap') }}
                        </span>
                        <h5 v-for="(tap, index) in details.last_tap" :key="index" class="mb-2">
                            <span class="badge rounded-3 bg-third me-2">
                                {{ tap.key }}
                            </span>
                            <span class="badge rounded-3 bg-second">
                                {{ tap.val }}
                            </span>
                        </h5>
                    </div>
                    <div class="col-12 col-sm-5 mb-4">
                        <span class="text-uppercase">
                            {{ $t('Dashboard.Tickets.Modals.TicketDetails.valid_from') }}
                        </span>
                        <h5 class="text-third text-break">
                            {{ this.$formatDate(details.valid_from, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone) }}
                        </h5>
                    </div>
                    <div class="col-12 col-sm-5 mb-4">
                        <span class="text-uppercase">
                            {{ $t('Dashboard.Tickets.Modals.TicketDetails.valid_to') }}
                        </span>
                        <h5 class="text-third text-break">
                            {{ this.$formatDate(details.valid_to, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone) }}
                        </h5>
                    </div>
                    <div class="col-12 d-flex justify-content-center flex-wrap">
                        <div class="col-12 col-sm-5">
                            <span class="btn btn-auth btn-auth-second w-100" @click="details = null">
                                {{ $t('Dashboard.Tickets.Modals.TicketDetails.close_details') }}
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import Modal from '@/components/Modal.vue'
import DataTable from '../../../../components/Dashboard/Travels/DataTables/TicketsDataTable.vue'
export default {
    name: 'TicketsView',
    components: {
        DataTable,
        Modal
    },
    computed: {
        ...mapState(['loader', 'account']),
        ...mapGetters(['apiPath', 'omsApiPath', 'omsApiKey'])
    },
    data() {
        return {
            selectedDate: null,
            tickets: null,
            details: null
        }
    },
    methods: {
        ...mapMutations(['setLoader', 'setErrorFromServer']),
        getTickets(startDate, endDate) {
            this.setLoader(true)
            if (!startDate && !endDate) {
                startDate = moment().subtract(29, 'days').format('YYYY-MM-DD')
                endDate = moment().format('YYYY-MM-DD')
            }
            this.$http
                .post(`${this.apiPath}/bank_cards/get`, { accounts_id: this.account.id }, {
                    headers: {
                        'x-api-key': this.omsApiKey
                    }
                })
                .then((res) => {
                    var bank_cards = res.data
                    var AliasPanPath = ''
                    for (var i = 0; i < bank_cards.length; i++) {
                        var bank_card = bank_cards[i]
                        AliasPanPath += `${bank_card.alias_pan}/`
                    }
                    this.$http
                        .get(`${this.omsApiPath}/tickets/get/${this.omsApiKey}/0/0/3:desc/${startDate}/${endDate}/${AliasPanPath}`)
                        .then((res) => {
                            var tickets = res.data.payload
                            for (var i = 0; i < tickets.length; i++) {
                                var ticket = tickets[i]
                                if (ticket.first_tap)  {
                                    var first_tap_data = []
                                    for (const [key, val] of Object.entries(ticket.first_tap)) {
                                        first_tap_data.push({key, val})
                                    }
                                    ticket.first_tap = first_tap_data
                                }

                                if (ticket.last_tap)  {
                                    var last_tap_data = []
                                    for (const [key, val] of Object.entries(ticket.last_tap)) {
                                        last_tap_data.push({key, val})
                                    }
                                    ticket.last_tap = last_tap_data
                                }
                            }
                            this.selectedDate = `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`
                            this.tickets = tickets
                            this.setLoader(false)
                        })
                        .catch((err) => {
                            this.setErrorFromServer(err.message)
                            this.setLoader(false)
                        })
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                    this.setLoader(false)
                })
        },
        filterByDate(when) {
            this.tickets = null
            this.getTickets(when.startDate, when.endDate)
        }
    },
    created() {
        this.getTickets()
    }
}
</script>