<template>
    <component :is="api_key"></component>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
export default {
    name: 'AccountDataChangesSucceeded',
    computed: {
        ...mapGetters(['omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../../layouts/${this.omsApiKey}/views/Dashboard/Account/AccountDataChangesSucceeded.vue`))
        }
    }
}
</script>