<template>
    <component :is="api_key" :tbody="BankCards" @getDetails="details = $event"></component>
    <transition name="fade-modal">
        <modal v-if="details" @close="details = null">
            <template v-slot:body>
                <component :is="BankCardDetails" :details="details" @close="details = null"></component>
            </template>
        </modal>
    </transition>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'
import Modal from '@/components/Modal.vue'
export default {
    name: 'BankCards',
    components : {
        Modal
    },
    computed: {
        ...mapState(['BankCards']),
        ...mapGetters(['omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../../../layouts/${this.omsApiKey}/views/Dashboard/Travels/BankCards/BankCards.vue`))
        },
        BankCardDetails() {
            return defineAsyncComponent(() => import(`../../../../../layouts/${this.omsApiKey}/components/Dashboard/Travels/Modals/BankCardDetails.vue`))
        }
    },
    data() {
        return {
            details: null
        }
    }
}
</script>