<template>
    <Loader v-if="loader"/>
    <router-view></router-view>
    <Version v-if="!isLoggedIn"/>
    <transition name="fade-modal">
        <modal v-if="errorFromServer" @close="setErrorFromServer(null)">
            <template v-slot:header>
                <div class="modal-header justify-content-between bg-danger text-white">
                    <div class="d-flex align-items-center">
                        <i class="fa-solid fa-globe me-2"></i>
                        <h5 class="modal-title">
                            {{ errorFromServer }}
                        </h5>
                    </div>
                    <div @click="this.$router.go(`${this.$route.path}`)">
                        <i class="fa-solid fa-rotate fs-4 cursor-pointer"></i>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
</template>

<script>
import $ from 'jquery'
import { mapState, mapGetters, mapMutations} from 'vuex'
import Loader from '@/components/Loader.vue'
import Modal from '@/components/Modal.vue'
import Version from '@/components/Version.vue'
export default {
    name: 'MyTravels',
    components: {
        Loader,
        Modal,
        Version
    },
    computed: {
        ...mapState(['config', 'errorFromServer', 'loader']),
        ...mapGetters(['apiPath', 'omsApiKey', 'isLoggedIn'])
    },
    methods: {
        ...mapMutations(['setErrorFromServer', 'setSupportedLanguages']),
        getSupportedLanguages() {
            this.$http
                .get(`${this.apiPath}/languages`, {
                    headers: {
                        "x-api-key": this.omsApiKey
                    }
                })
                .then((res) => {
                    this.setSupportedLanguages(res.data)
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                })
        },
        setFavicons(icon) {
            let headTitle = document.querySelector('head')
            let setFavicon = document.createElement('link')
            setFavicon.setAttribute('rel', 'icon')
            setFavicon.setAttribute('href', icon)
            headTitle.appendChild(setFavicon)
        }
    },
    created() {
        document.title = this.config.tenant
        $('title').after(`<meta name="description" content="Benvenuti nel Portale del servizio Pay&Go del Malpensa Express: entra ora e gestisci le carte e i biglietti acquistati in modalità contactless!"/>`)
        this.setFavicons(`/${this.omsApiKey}.ico`)
        this.getSupportedLanguages()
        this.$store.dispatch('autoLogin')
        if (this.isLoggedIn) {
            $('body').addClass('isLoggedIn')
        }
    }
}
</script>