<template>
    <component :is="api_key" :sent_to="this.$route.query.sent_to"></component>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
export default {
    name: 'SentEmailIfExists',
    computed: {
        ...mapGetters(['omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../layouts/${this.omsApiKey}/views/Auth/SentEmailIfExists.vue`))
        }
    },
}
</script>