<template>
    <component :is="api_key"></component>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
    name: 'DashboardView',
    computed: {
        ...mapState(['account']),
        ...mapGetters(['apiPath', 'omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../layouts/${this.omsApiKey}/views/Dashboard/Dashboard.vue`))
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer', 'setLoader', 'setBankCards']),
        getBankCards() {
            this.setLoader(true)
            this.$http
                .post(`${this.apiPath}/bank_cards/get`, { accounts_id: this.account.id }, {
                    headers: {
                        'x-api-key': this.omsApiKey
                    }
                })
                .then((res) => {
                    var bank_cards = res.data
                    for (var i = 0; i < bank_cards.length; i++) {
                        var bank_card = bank_cards[i]
                        bank_card.label = this.$formatLabel(bank_card.label)
                        bank_card.created = this.$formatDate(bank_card.created, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                        bank_card.updated = this.$formatDate(bank_card.updated, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                    }
                    this.setBankCards(bank_cards)
                    this.setLoader(false)
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                    this.setLoader(false)
                })
        }
    },
    created() {
        this.getBankCards()
    }
}
</script>