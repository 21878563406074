<template>
    <component :is="api_key"></component>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
export default {
    name: 'AccountDeleted',
    computed: {
        ...mapGetters(['omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../../layouts/${this.omsApiKey}/views/Dashboard/Account/AccountDeleted.vue`))
        }
    },
    created() {
        setTimeout(() => {
            this.$store.dispatch('logout')
        }, 1500)
    }
}
</script>