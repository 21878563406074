<template>
    <!-- <pdf :src="`/${this.$store.state.config.oms_api.key}.pdf`"></pdf> -->
    <div>
        PDF
    </div>
</template>

<script>
// import pdf from 'vue3-pdf'

export default {
    name: 'PrivacyView',
    components: {
        // pdf
    }
}
</script>

<style lang="scss" scoped>

</style>