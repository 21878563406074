<template>
    <HeaderComp/>
    <main class="p-5">
        <h1 class="text-white fw-bold mb-4 position-relative z-3">
            MALPENSA EXPRESS
        </h1>
        <h4 class="text-white mb-4  position-relative z-3">
            Un servizio che connette la Lombardia
            <br>
            con il mondo
        </h4 >
        <div class="col-12 col-md-2 position-relative z-3">
            <span class="btn btn-auth btn-auth-white w-100" @click="loginWithAzure">
                {{ $t('Auth.Login.login') }}
            </span>
        </div>
    </main>
    <FooterComp/>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { PublicClientApplication } from '@azure/msal-browser'
import HeaderComp from '../../components/Auth/Header.vue'
import FooterComp from '../../components/Auth/Footer.vue'
export default {
    name: 'LoginView',
    props: {
        errors: Object,
        passwordShowed: Boolean
    },
    components: {
        HeaderComp,
        FooterComp
    },
    computed: {
        ...mapState(['config']),
        ...mapGetters(['omsApiKey', 'apiPath'])
    },
    data() {
        return {
            msal: null
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer', 'setAccount']),
        async loginWithAzure() {
            await this.msal.loginPopup({}).then(() => {
                var response = this.msal.getAllAccounts()[0].idTokenClaims
                this.$http
                    .post(`${this.apiPath}/login_with_azure`, {response}, {
                        headers: {
                            'x-api-key': this.omsApiKey
                        }
                    })
                    .then((res) => {
                        if (res.data.account) {
                            this.setAccount(res.data.account)
                            this.$router.go('/dashboard')
                        }
                    })
                    .catch((err) => {
                        this.setErrorFromServer(err.message)
                    })
            }).catch((err) => {
                console.log(err)
            })
        },
        async logoutAzure() {
            await this.msal.logoutPopup()
        }
    },
    async mounted() {
        await this.msal.initialize()
        if (this.msal.getAllAccounts().length !== 0) {
            await this.logoutAzure()
        } else {
            await this.loginWithAzure()
        }
    },
    created() {
        this.msal = new PublicClientApplication({auth: this.config.social_login.azure.auth})
    }
}
</script>

<style lang="scss" scoped>
    main {
        position: relative;
        background-image: url('@/assets/img/tenants/f7fb61e7-ee2f-47c1-ad44-02c1c161c94f/background.jpg');
        background-size: cover;
        background-repeat: no-repeat;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            background: linear-gradient(to right, #121212, transparent);
        }
    }
</style>