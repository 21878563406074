<template>
    <component :is="api_key" @insertOTP="insertOTP" @deletionConfirm="deletionConfirm" :errors="errors"></component>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
    name: 'DeletionConfirm',
    computed: {
        ...mapState(['account']),
        ...mapGetters(['apiPath', 'omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../../layouts/${this.omsApiKey}/views/Dashboard/Account/DeletionConfirm.vue`))
        }
    },
    data() {
        return {
            OTP: null,
            errors: {}
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer']),
        insertOTP(value) {
            if (this.errors.OTP) {
                this.errors.OTP = null
            }
            this.OTP = value
        },
        deletionConfirm() {
            this.$http
                .post(`${this.apiPath}/deletion_confirm`, { id: this.account.id, OTP: this.OTP }, {
                    headers: {
                        "x-api-key": this.omsApiKey,
                    }
                })
                .then((res) => {
                    if (res.data.errors) {
                        var errors = res.data.errors
                        for (var i = 0; i < errors.length; i++) {
                            var error = errors[i]
                            if (error.path == 'OTP') {
                                this.errors.OTP = this.$t(`Dashboard.DeletionConfirm.errors.OTP.${error.msg}`)
                            }
                        }
                    } else {
                        switch (res.data) {
                            case 'Invalid OTP':
                                this.errors.OTP = this.$t('Dashboard.DeletionConfirm.errors.OTP.invalid')
                                break;
                            default:
                                this.$router.replace('/dashboard/account/deleted')
                                break;
                        }
                    }
                })
                .catch((err) => {
                    this.setErrorFromServer(err)
                })
        }
    }
}
</script>