<template>
    <component :is="api_key"></component>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'ForgotPasswordRequestInProgress',
    computed: {
        ...mapGetters(['apiPath', 'omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../../../layouts/${this.omsApiKey}/views/Auth/ForgotPasswordRequestInProgress.vue`))
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer']),
        forgot_password() {
            this.$http
                .post(`${this.apiPath}/forgot_password`, { token: this.$route.query.token }, {
                    headers: {
                        "x-api-key": this.omsApiKey
                    },
                })
                .then((res) => {
                    switch (res.data) {
                        case 'Request Expired':
                            this.$router.replace('/request/expired')
                            break;
                    
                        default:
                            this.$router.replace({
                                path: '/reset_password',
                                query: { token: res.data }
                            })
                            break;
                    }
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                })
        }
    },
    created() {
        this.forgot_password()
    }
}
</script>