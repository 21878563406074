import { createI18n } from 'vue-i18n'
import en from './translations/en.json'
import it from './translations/it.json'
import fr from './translations/fr.json'
import de from './translations/de.json'
import config from '../../config.json'

if (localStorage.getItem('session_account') == null) {
  if (config.supported_languages.includes(navigator.language.slice(0,2))) {
    localStorage.setItem('session_language', navigator.language.slice(0,2))
  } else {
    localStorage.setItem('session_language', config.default_language)
  }
}

const i18n = createI18n({
    locale: localStorage.getItem('session_language'),
    messages: {
      en,
      it,
      fr,
      de
    }
});


export default i18n;